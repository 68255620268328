/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "../bootstrap.scss"
import "../bierimport.scss"
import "./layout.scss"
import BeerImage from "../images/wave.svg"

const Layout = ({ children, siteTitle }) => {
  return (
    <div className="Layout">
      <Header siteTitle={siteTitle} />
      <main>{children}</main>
      <footer className="">
        <div className="ocean-wrapper">
          <div className="ocean">
            <div className="wave" style={{backgroundImage: 'url(' + BeerImage + ')'}}></div>
            <div className="wave" style={{backgroundImage: 'url(' + BeerImage + ')'}}></div>
          </div>
          <div className="container">
            {/* © {new Date().getFullYear()} BierImport */}
          </div>
        </div>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  siteTitle: PropTypes.string,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  siteTitle: ``,
}

export default Layout
